var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp" },
    [
      _c("div", { staticClass: "platformStatic" }, [
        _c("div", { staticClass: "staticList" }, [
          _c("p", { staticClass: "title" }, [_vm._v(" 接入概况 ")]),
          _c("ul", { staticClass: "staticCon" }, [
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 设备数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoEquip } },
                [_vm._v(" 100 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 产品数 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoProduct } },
                [_vm._v(" 100 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 协议包 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoPackage } },
                [_vm._v(" 20 ")]
              ),
            ]),
            _c("li", [
              _c("p", { staticClass: "staticTitle" }, [_vm._v(" 网络组件 ")]),
              _c(
                "p",
                { staticClass: "staticNum", on: { click: _vm.gotoUnit } },
                [_vm._v(" 20 ")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "staticList" }, [
          _c(
            "div",
            { staticStyle: { margin: "25px 0 0 25px" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "240px" },
                  attrs: { "default-value": "all" },
                },
                [
                  _c("a-select-option", { attrs: { value: "all" } }, [
                    _vm._v(" 全部标准协议产品 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "warn" } }, [
                    _vm._v(" 产品1 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "error" } }, [
                    _vm._v(" 产品2 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "info" } }, [
                    _vm._v(" 产品3 "),
                  ]),
                ],
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "240px", margin: "0 25px" },
                  attrs: { "default-value": "all" },
                },
                [
                  _c("a-select-option", { attrs: { value: "all" } }, [
                    _vm._v(" 接入状态（全部） "),
                  ]),
                  _c("a-select-option", { attrs: { value: "warn" } }, [
                    _vm._v(" 已完成 "),
                  ]),
                  _c("a-select-option", { attrs: { value: "error" } }, [
                    _vm._v(" 进行中 "),
                  ]),
                ],
                1
              ),
              _c("a-input-search", {
                staticStyle: { width: "300px" },
                attrs: {
                  placeholder: "输入产品或者设备名称快速搜索",
                  "enter-button": "",
                },
              }),
            ],
            1
          ),
          _c("ul", { staticClass: "cardListWrap" }, [
            _c(
              "li",
              { staticClass: "tabList", on: { click: _vm.addProduct } },
              [
                _c("a-icon", {
                  staticStyle: { "font-size": "21px", "margin-right": "16px" },
                  attrs: { type: "plus" },
                }),
                _c("span", [_vm._v("添加产品")]),
              ],
              1
            ),
            _c("li", { staticClass: "tabList" }, [
              _c("p", [_vm._v("产品名称：")]),
              _c("p", [_vm._v("产品描述：")]),
              _c("p", [_vm._v("当前接入设备数：")]),
              _c("p", { staticClass: "historyData" }, [
                _c("span", { on: { click: _vm.details } }, [_vm._v("详情")]),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-left": "25px" },
                    on: { click: _vm.onDelete },
                  },
                  [_vm._v("删除")]
                ),
              ]),
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
          ]),
        ]),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            width: 900,
            title: "产品详情",
            visible: _vm.isVisible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            cancel: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c("ul", [
            _c("li", [_vm._v("服务状态：")]),
            _c("li", [_vm._v("key：")]),
            _c("li", [_vm._v("当前接入设备数：")]),
            _c("li", [_vm._v("产品描述：")]),
            _c("li", [_vm._v("创建时间：")]),
          ]),
          _c("a-table", {
            staticStyle: { "margin-bottom": "20px" },
            attrs: {
              "table-layout": "auto",
              locale: { emptyText: "暂无数据" },
              columns: _vm.columns,
              pagination: false,
              "data-source": _vm.data,
            },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoDetails(record)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: { margin: "0 20px" },
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(record)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(record)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "tabList" }, [
      _c("p", [_vm._v("产品名称：")]),
      _c("p", [_vm._v("产品描述：")]),
      _c("p", [_vm._v("当前接入设备数：")]),
      _c("p", { staticClass: "historyData" }, [
        _c("span", [_vm._v("详情")]),
        _c("span", { staticStyle: { "margin-left": "25px" } }, [
          _vm._v("删除"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "tabList" }, [
      _c("p", [_vm._v("产品名称：")]),
      _c("p", [_vm._v("产品描述：")]),
      _c("p", [_vm._v("当前接入设备数：")]),
      _c("p", { staticClass: "historyData" }, [
        _c("span", [_vm._v("详情")]),
        _c("span", { staticStyle: { "margin-left": "25px" } }, [
          _vm._v("删除"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "tabList" }, [
      _c("p", [_vm._v("产品名称：")]),
      _c("p", [_vm._v("产品描述：")]),
      _c("p", [_vm._v("当前接入设备数：")]),
      _c("p", { staticClass: "historyData" }, [
        _c("span", [_vm._v("详情")]),
        _c("span", { staticStyle: { "margin-left": "25px" } }, [
          _vm._v("删除"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "tabList" }, [
      _c("p", [_vm._v("产品名称：")]),
      _c("p", [_vm._v("产品描述：")]),
      _c("p", [_vm._v("当前接入设备数：")]),
      _c("p", { staticClass: "historyData" }, [
        _c("span", [_vm._v("详情")]),
        _c("span", { staticStyle: { "margin-left": "25px" } }, [
          _vm._v("删除"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }