<template>
    <div class="warp">
        <div class="platformStatic">
            <div class="staticList">
                <p class="title">
                    接入概况
                </p>
                <ul class="staticCon">
                    <li>
                        <p class="staticTitle">
                            设备数
                        </p>
                        <p class="staticNum" @click="gotoEquip">
                            100
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            产品数
                        </p>
                        <p class="staticNum" @click="gotoProduct">
                            100
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            协议包
                        </p>
                        <p class="staticNum" @click="gotoPackage">
                            20
                        </p>
                    </li>
                    <li>
                        <p class="staticTitle">
                            网络组件
                        </p>
                        <p class="staticNum" @click="gotoUnit">
                            20
                        </p>
                    </li>
                </ul>
            </div>
            <div class="staticList">
                <div style="margin: 25px 0 0 25px">
                    <a-select default-value="all" style="width: 240px;">
                        <a-select-option value="all">
                            全部标准协议产品
                        </a-select-option>
                        <a-select-option value="warn">
                            产品1
                        </a-select-option>
                        <a-select-option value="error">
                            产品2
                        </a-select-option>
                        <a-select-option value="info">
                            产品3
                        </a-select-option>
                    </a-select>
                    <a-select default-value="all" style="width: 240px; margin: 0 25px">
                        <a-select-option value="all">
                            接入状态（全部）
                        </a-select-option>
                        <a-select-option value="warn">
                            已完成
                        </a-select-option>
                        <a-select-option value="error">
                            进行中
                        </a-select-option>
                    </a-select>
                    <a-input-search placeholder="输入产品或者设备名称快速搜索" enter-button style="width: 300px;" />
                </div>
                <ul class="cardListWrap">
                    <li class="tabList" @click="addProduct">
                        <a-icon type="plus" style="font-size: 21px; margin-right: 16px" />
                        <span>添加产品</span>
                    </li>
                    <li class="tabList">
                        <p>产品名称：</p>
                        <p>产品描述：</p>
                        <p>当前接入设备数：</p>
                        <p class="historyData">
                            <span @click="details">详情</span>
                            <span style="margin-left: 25px" @click="onDelete">删除</span>
                        </p>
                    </li>
                    <li class="tabList">
                        <p>产品名称：</p>
                        <p>产品描述：</p>
                        <p>当前接入设备数：</p>
                        <p class="historyData">
                            <span>详情</span>
                            <span style="margin-left: 25px">删除</span>
                        </p>
                    </li>
                    <li class="tabList">
                        <p>产品名称：</p>
                        <p>产品描述：</p>
                        <p>当前接入设备数：</p>
                        <p class="historyData">
                            <span>详情</span>
                            <span style="margin-left: 25px">删除</span>
                        </p>
                    </li>
                    <li class="tabList">
                        <p>产品名称：</p>
                        <p>产品描述：</p>
                        <p>当前接入设备数：</p>
                        <p class="historyData">
                            <span>详情</span>
                            <span style="margin-left: 25px">删除</span>
                        </p>
                    </li>
                    <li class="tabList">
                        <p>产品名称：</p>
                        <p>产品描述：</p>
                        <p>当前接入设备数：</p>
                        <p class="historyData">
                            <span>详情</span>
                            <span style="margin-left: 25px">删除</span>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 查看详情弹窗 -->
        <a-modal
            :width="900"
            title="产品详情"
            :visible="isVisible"
            ok-text="确认"
            cancel-text="取消"
            @cancel="isVisible = false">
            <ul>
                <li>服务状态：</li>
                <li>key：</li>
                <li>当前接入设备数：</li>
                <li>产品描述：</li>
                <li>创建时间：</li>
            </ul>
            <a-table style="margin-bottom: 20px" table-layout="auto" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data">
                <template slot="operation" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="gotoDetails(record)">详情</a>
                    <a href="javascript:void(0);" style="margin: 0 20px" @click="onEdit(record)">编辑</a>
                    <a href="javascript:void(0);" @click="deleteItem(record)">删除</a>
                </template>
            </a-table>
        </a-modal>
    </div>
</template>

<script>
const columns = [
    {
        className: '',
        title: '产品名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        className: '',
        title: '产品ID',
        dataIndex: 'key',
        key: 'key'
    },
    {
        className: '',
        title: '节点类型',
        dataIndex: 'type',
        key: 'type'
    },
    {
        className: '',
        title: '接入协议',
        dataIndex: 'desc',
        key: 'desc'
    },
    {
        className: '',
        title: '创建时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        className: '',
        title: '操作',
        width: '200px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        name: '测试项目1',
        key: '1',
        desc: '已受理',
        time: '今天'
    },
    {
        name: '测试项目2',
        key: '2',
        desc: '已受理',
        time: '今天'
    },
    {
        name: '测试项目3',
        key: '3',
        desc: '',
        time: '今天'
    },
]
export default {
    name: "Index",
    data() {
        return {
            // 查看详情弹窗是否显示
            isVisible: false,
            columns,
            data
        }
    },
    methods: {
        // 点击添加产品
        addProduct () {
            this.$router.push({path:'/product-list', query: {fromPage:'home'}})
        },
        // 点击设备数进入设备列表页
        gotoEquip() {
            this.$router.push('/equip-list')
        },
        // 点击产品数进入产品列表页
        gotoProduct() {
            this.$router.push('/product-list')
        },
        // 点击项目数进入协议包页
        gotoPackage() {
            // this.$router.push('/project-manage')
        },
        // 点击项目数进入网络组件
        gotoUnit() {
            // this.$router.push('/project-manage')
        },
        // 查看详情
        details() {
            this.isVisible = true
        },
        // 删除
        onDelete() {
            this.$confirm({
                title: '您确认删除该项吗？',
                content: '删除该产品下的所有设备以后才可以删除产品',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    console.log('OK')
                },
                onCancel() {
                    console.log('Cancel')
                },
            })
        },
        // 点击查看详情弹窗表格中的详情
        gotoDetails() {},
        // 点击查看详情弹窗表格中的编辑
        onEdit() {},
        // 点击查看详情弹窗表格中的删除
        deleteItem() {}
    }
}
</script>

<style lang="scss" scoped>
    .warp {
        display: flex;
        min-height: 90vh;
        padding: 25px;
        background: #F0F0F7;
    }
    .platformStatic {
        flex: 1;
    }
    .staticList {
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 8px;
        margin-bottom: 20px;
        color: #818181;
        .cardListWrap {
            display: flex;
            flex-wrap: wrap;
            margin: 25px 25px 0;
            .tabList {
                width: 23.5%;
                margin: 0 2% 25px 0;
                height: 160px;
                padding: 0 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid #F0F0F0;
                color: #4D4F5C;
                background: #fff;
                .historyData {
                    text-align: right;
                    cursor: pointer;
                    color: #1890ff;
                    margin: 0;
                    span {
                        font-size: 12px;
                    }
                }
            }
            .tabList:first-of-type {
                cursor: pointer;
                flex-direction: row;
                align-items: center
            }
            .tabList:nth-of-type(4n) {
                margin-right: 0;
            }
        }
        .title {
            color: #4D4F5C;
            font-size: 16px;
            font-weight: bold;
            padding: 16px;
            margin: 0;
        }
        .staticCon {
            display: flex;
            margin: 0;
            color: #818181;
            li {
                height: 129px;
                border: 1px solid #F0F0F0;
                border-right: none;
                padding: 16px 0 16px 16px;
                flex: 1;
                background-size: 70px;
                background-repeat: no-repeat;
                background-position: right bottom;
                border-bottom: none;
                .staticNum {
                    font-weight: bold;
                    font-size: 32px;
                    color: #4D4F5C;
                    cursor: pointer;
                }
            }
            li:nth-of-type(1) {
                border-left: none;
                background-image: url("~@/assets/img/equip.png");
            }
            li:nth-of-type(2) {
                background-image: url("~@/assets/img/product.png");
            }
            li:nth-of-type(3) {
                background-image: url("~@/assets/img/package.png");
            }
            li:last-of-type {
                background-image: url("~@/assets/img/unit.png");
                margin-right: 0;
                border-right: none;
            }
        }
        ::v-deep .ant-tabs-tabpane img{
            margin-bottom: 20px;
        }
    }
</style>
